import { InnovationContentCard } from '../InnovationContentCard/InnovationContentCard.component';
import React, { FC } from 'react';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { formatDateCompact, formatDateToIso } from '@utils/date';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classNames from 'classnames';
import { InnovationContentCardProps } from '../InnovationContentCard/InnovationContentCard.types';

// import './InnovationCard.scss';

type Props = Omit<InnovationContentCardProps, 'subTitle' | 'actions' | 'href'> & {
  slug: string;
  date: Date;
  title: string;
};

const InnovationCard: FC<Props> = ({
  date,
  className,
  slug,
  title,
  description,
  sectors,
  domains,
  stakes,
  isHighlightCard = false,
  ...rest
}) => {
  const linkProps = getSlugLinkProps(urlEnum.innovations, slug);
  const rootClass = classNames('innovation-card', className);
  const subTitle = date ? <time dateTime={formatDateToIso(date)}>{formatDateCompact(date)}</time> : null;
  const actions = <TextWithArrow i18nKey="innovations.card-action" tag="span" variant="p" />;

  return (
    <InnovationContentCard
      cardActions={actions}
      cardSubTitle={subTitle}
      cardTitle={title}
      className={rootClass}
      description={description}
      domains={domains}
      isHighlightCard={isHighlightCard}
      sectors={sectors}
      stakes={stakes}
      {...linkProps}
      {...rest}
    />
  );
};

export { InnovationCard };
