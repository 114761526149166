import { LinkProps } from 'next/link';
import { ActivityCode, InterestCode } from 'pleinchamp-api-client';
import React, { ComponentPropsWithRef, FC, useMemo, useRef } from 'react';
import { getApiEnumLabel } from '@api/business/api.utils';
import { Tag } from '@components/Tag/Tag.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { useTranslation } from '@locales/useTranslation.hook';
import { activityInUrl, interestInUrl } from '@search/business/search.types';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';

import './ContentTagList.scss';

type Props = ComponentPropsWithRef<'div'> & {
  tags: Array<InterestCode | ActivityCode>;
  asLink?: boolean;
  oneLine?: boolean;
};

function buildTagLinkProps(code: InterestCode | ActivityCode): LinkProps | undefined {
  if (Object.keys(interestInUrl).includes(code)) {
    return { as: `${urlEnum.interests}/${interestInUrl[code as InterestCode]}`, href: `${urlEnum.interests}/[tag]` };
  }
  if (Object.keys(activityInUrl).includes(code)) {
    return { as: `${urlEnum.activities}/${activityInUrl[code as ActivityCode]}`, href: `${urlEnum.activities}/[tag]` };
  }
  return undefined;
}

const LocalTag: FC<{
  code: InterestCode | ActivityCode;
  asLink?: boolean;
}> = ({ code, asLink }) => {
  const { t } = useTranslation(['search']);
  const ref = useRef<HTMLAnchorElement>(null);
  const linkProps = useMemo(() => (asLink ? buildTagLinkProps(code) : undefined), [code, asLink]);

  const tag = <Tag ref={ref}>{getApiEnumLabel(code, t)}</Tag>;

  return linkProps ? (
    <PlcAnchor key={code} linkProps={linkProps}>
      {tag}
    </PlcAnchor>
  ) : (
    tag
  );
};

const ContentTagList: FC<Props> = ({ tags, asLink = true, oneLine = false, className, ...rest }) => {
  const rootClassName = classnames('content-tags-list', { 'one-line': oneLine }, className);

  return (
    <div className={rootClassName} {...rest}>
      {tags.map(tag => (
        <LocalTag key={tag} asLink={asLink} code={tag} />
      ))}
    </div>
  );
};

export { ContentTagList };
