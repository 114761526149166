import React, { ComponentPropsWithRef, FC } from 'react';
import { GuideIcon } from '@components/icons/Guide.icon';
import { Text } from '@components/Text/Text.component';
import classnames from 'classnames';

import './GuideEpisodesHeader.scss';

type Props = ComponentPropsWithRef<'div'> & {
  count: number;
};

export const GuideEpisodesHeader: FC<Props> = ({ count, className, ...rest }) => {
  const rootClass = classnames('guide-episodes-header flex', className);
  return (
    <span className={rootClass} {...rest}>
      <GuideIcon className="plc-mr-xs" height={18} width={18} />
      <Text i18nKey="guides.episodes.title" i18nOptions={{ count }} tag="span" variant="h6" />
    </span>
  );
};
