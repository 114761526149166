import { Service } from 'pleinchamp-api-client';
import React, { ComponentProps, FC, MouseEvent, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthModal } from '@auth/business/AuthModal.hook';
import { FavoriteButton } from '@components/buttons/FavoriteButton/FavoriteButton.component';
import { Text } from '@components/Text/Text.component';
import { useModal } from '@layout/business/Layout.utils';
import { MAX_PREFERENCES_SERVICES } from '@settings/business/settings.utils';
import { PlcDispatch, RootState } from '@store/store';

type Props = Omit<ComponentProps<typeof FavoriteButton>, 'onClick' | 'isFavorite'> & { serviceSlug: Service['slug'] };

const ServiceFavoriteButton: FC<Props> = ({ serviceSlug, withLabel = true, ...rest }) => {
  const {
    services: { services },
    auth: { isAuthenticated },
  } = useSelector((state: RootState) => state);
  const {
    services: { addUserService, removeUserService },
  } = useDispatch<PlcDispatch>();
  const { showSignInModal } = useAuthModal();
  const { show: showModal } = useModal();

  const isFavorite = useMemo(() => services.some(s => s.slug === serviceSlug), [serviceSlug, services]);

  function handleSetFavorite(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!isAuthenticated) {
      showSignInModal();
    } else if (isFavorite) {
      removeUserService(serviceSlug);
    } else if (services.length < MAX_PREFERENCES_SERVICES) {
      addUserService(serviceSlug);
    } else {
      showModal({
        children: (
          <Text variant="p">
            <Trans i18nKey="services.home.max-favorites" ns="services">
              Vous avez atteint <br /> Vous devez en supprimer
            </Trans>
          </Text>
        ),
      });
    }
  }

  return <FavoriteButton isFavorite={isFavorite} onClick={handleSetFavorite} withLabel={withLabel} {...rest} />;
};

export { ServiceFavoriteButton };
