import React, { ComponentPropsWithRef, FC, forwardRef } from 'react';
import { BasicButton } from '@components/buttons/BasicButton/BasicButton.component';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { Text } from '@components/Text/Text.component';
import { CloseIcon } from '@icons/Close.icon';
import { useTranslation } from '@locales/useTranslation.hook';
import classnames from 'classnames';

import './Tag.scss';

export interface TagProps extends ComponentPropsWithRef<'a'> {
  onClose?: () => void;
  isPro?: boolean;
}

const Tag: FC<TagProps> = forwardRef(({ isPro, children, onClose, className, ...rest }, tagRef) => {
  const { t } = useTranslation(['search']);
  const closeButton = React.createRef<HTMLButtonElement>();
  const rootClasses = classnames(
    'plc-tag',
    {
      removable: !!onClose,
    },
    className
  );

  const textFlavour = isPro ? 'dark' : 'light';

  const tagContent = (
    <>
      <span>{children}</span>
      {onClose && (
        <BasicButton
          ref={closeButton}
          aria-label={t('search.remove-tag', { tagName: children ? children.toString() : null })}
          name={t('common.close')}
          onClick={onClose}
          type="button"
        >
          <CloseIcon height="16" width="16" />
        </BasicButton>
      )}
    </>
  );

  if (rest.href) {
    return (
      <BasicLink ref={tagRef} className={rootClasses} textProps={{ flavour: textFlavour, variant: 'tag' }} {...rest}>
        {tagContent}
      </BasicLink>
    );
  }

  return (
    <Text
      ref={tagRef as React.Ref<HTMLHeadingElement>}
      className={rootClasses}
      flavour={textFlavour}
      tag="span"
      variant="tag"
    >
      {tagContent}
    </Text>
  );
});

export { Tag };
