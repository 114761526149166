import { Tag } from 'pleinchamp-api-client';
import React, { ComponentPropsWithRef, FC, useRef } from 'react';
import { Tag as TagComponent } from '@components/Tag/Tag.component';
import classnames from 'classnames';

import './InnovationTagList.scss';

type Props = ComponentPropsWithRef<'div'> & {
  tags: Array<Tag>;
  oneLine?: boolean;
  showAll?: boolean;
};
function truncate(str: string, n: number) {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str;
}
const LocalTag: FC<{
  tag: Tag;
  show: boolean;
}> = ({ tag, show }) => {
  const ref = useRef<HTMLAnchorElement>(null);
  if (show) {
    return (
      <>
        <TagComponent ref={ref} className="max-width-inno">
          {truncate(tag.label, 36)}
        </TagComponent>
        <TagComponent>...</TagComponent>
      </>
    );
  }
  return (
    <TagComponent ref={ref} className="tag-inno">
      {tag.label}
    </TagComponent>
  );
};

const InnovationTagList: FC<Props> = ({ tags, showAll = false, oneLine = false, className }) => {
  const rootClassName = classnames({ 'one-line': oneLine }, className);
  if (showAll) {
    return (
      <div className={rootClassName}>
        {tags.map(tag => (
          <LocalTag key={tag.remoteId} show={false} tag={tag} />
        ))}
      </div>
    );
  }
  return (
    <div className={rootClassName}>
      <LocalTag key={tags[0].remoteId} show={tags.length > 1} tag={tags[0]} />
    </div>
  );
};

export { InnovationTagList };
